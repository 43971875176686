import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import Box from '@material-ui/core/Box';
import MainLayout from '../components/MainLayout';
import { getUrlParams } from '../helpers/location';
import {
  FacebookShareButton,
  LineShareButton,
  FacebookIcon,
  LineIcon,
} from 'react-share';
import LinkIcon from '@material-ui/icons/Link';
import Button from '../components/Element/Button';
import SEO from '../components/SEO';
import copy from 'copy-to-clipboard';
import appLogo from '../../content/assets/appLogo.png';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 0,
    wordBreak: 'break-word',
  },
  detail: {
    fontStyle: 'italic',
    color: 'grey',
    fontWeight: 'normal',
    wordBreak: 'break-word',
  },
  shareBox: {
    display: 'inline-grid',
    gridGap: '10px',
    gridAutoFlow: 'column',
    alignItems: 'center',
    padding: '10px 0px',
  },
  linkButton: {
    width: '35px',
    height: '35px',
    minHeight: '35px',
    marginTop: '-5px',
  },
}));

const BlogViewPage = ({ pageContext, location }) => {
  const classes = useStyles();
  const blog = pageContext.blog;
  const blogUrl = location.href;

  if (!blog) {
    return false;
  }

  return (
    <MainLayout>
      <SEO
        seo_title={blog.seo_title}
        topic={blog.topic}
        detail={blog.detail}
        seo_description={blog.seo_description}
        image={blog.cover.small}
      />
      <>
        <Box p={2}>
          <h2 className={classes.header}>{blog.topic}</h2>
          <h3 className={classes.detail}>{blog.detail}</h3>
          <hr style={{ border: '0.5px solid #cecece' }} />
          <Box className={classes.shareBox}>
            <span>แชร์บทความนี้</span>
            <FacebookShareButton url={blogUrl}>
              <FacebookIcon size={35} round={true} />
            </FacebookShareButton>
            <LineShareButton url={blogUrl}>
              <LineIcon size={35} round={true} />
            </LineShareButton>
            <Button
              color='primary'
              type='fab'
              className={classes.linkButton}
              onClick={() => copy(blogUrl)}
            >
              <LinkIcon />
            </Button>
          </Box>
          <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        </Box>
      </>
    </MainLayout>
  );
};

export default BlogViewPage;
