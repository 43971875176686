import React from 'react';
import Helmet from 'react-helmet';

function SEO({ detail, seo_title, seo_description, image, topic }) {
  return (
    <Helmet>
      <title>{seo_title || topic}</title>
      <meta name="description" content={seo_description || detail} />
      <meta name="title" content={seo_title || topic} />
      <meta name="og:title" content={seo_title || topic} />
      <meta name="og:description" content={detail} />
      <meta name="og:image" content={image} />
      <meta name="og:image:width" content={image.width} />
      <meta name="og:image:height" content={image.height} />
    </Helmet>
  );
}

export default SEO;